// Libs
import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements, ElementsConsumer,
    CardNumberElement, CardExpiryElement, CardCvcElement
} from '@stripe/react-stripe-js';

// Services
import OnlineBookingService from 'services/OnlineBookingService';

// Components
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class StripeForm extends Component {

    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);

        this.stripePromise = loadStripe(props.stripePublicKey);

        this.options = {
            fonts: [], // TODO
        };

        this.elementOptions = {
            style: {
                base: {
                    fontSize: '18px',
                    color: '#000',
                    letterSpacing: '0.025em'
                },
                invalid: {
                    color: '#FF0000'
                }
            }
        };
        this.elementOptionsNoPlaceholder = {
            style: {
                ...this.elementOptions,
                base: {
                    ...this.elementOptions.style.base,
                    '::placeholder': {
                        color: '#efefef'
                    }
                }
            }
        };

        this.state = {
            isLoading: true
        };
    }

    async componentDidMount() {
        // If the payment was already made, skip payment
        const isPaymentAlreadyMade = await OnlineBookingService.isPaymentAlreadyMade();
        if (isPaymentAlreadyMade) {
            this.props.onComplete();
        }
        this.setState({ isLoading: false });
    }

    setIsBusy(isBusy, elements) {
        const inputs = [
            elements.getElement(CardNumberElement),
            elements.getElement(CardExpiryElement),
            elements.getElement(CardCvcElement)
        ];
        inputs.forEach(i => i.update({ disabled: isBusy }));
        this.props.setIsBusy(isBusy);
    }

    async submit(stripe, elements, e) {
        e.preventDefault();
        const {
            onError,
            acceptTermsConditions,
            onComplete,
            booking,
            customer
        } = this.props;
        const { name, postcode } = this.state;

        // Validation
        if (elements == null) {
            onError('Sorry, the payment gateway is not ready yet. Please try in again a moment.');
            return;
        }
        if (!name) {
            onError('Please provide the name on your card');
            return;
        }
        if (!postcode) {
            onError('Please provide your billing postcode');
            return;
        }
        if (!acceptTermsConditions) {
            onError('Please tick to confirm that you agree to the T&Cs');
            return;
        }

        this.setIsBusy(true, elements);

        try {

            // Create payment method
            const createPaymentMethodResult = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name,
                    address: {
                        postal_code: postcode
                    }
                }
            });

            // Error creating payment method
            if (createPaymentMethodResult.error) {
                onError(createPaymentMethodResult.error.message);
                this.setIsBusy(false, elements);
                return;
            }

            // Link payment method to customer, init payment intent
            const stripePaymentMethodID = createPaymentMethodResult.paymentMethod.id;
            const initDepositPaymentResult = await OnlineBookingService.initDepositPayment(
                booking,
                stripePaymentMethodID
            );

            // If deposit is 0, just capture card details. Otherwise take payment
            if (booking.depositAmount > 0) {

                // Make the payment
                const confirmCardPaymentResult = await stripe.confirmCardPayment(initDepositPaymentResult.paymentIntentClientSecret, {
                    setup_future_usage: 'off_session',
                    payment_method: stripePaymentMethodID,
                    receipt_email: customer.email
                });

                // Catch error
                if (confirmCardPaymentResult.error) {
                    onError(confirmCardPaymentResult.error.message);
                    this.setIsBusy(false, elements);
                    return;
                }
                
            }

            this.setIsBusy(false, elements);
            onComplete();

        } catch (error) {
            onError(error);
            this.setIsBusy(false, elements);
        }
    }
    
    render() {
        const {
            render,
            isDisabled
        } = this.props;
        const {
            name,
            postcode,
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <Elements stripe={this.stripePromise} options={this.options}> 
                <ElementsConsumer>
                    {({ stripe, elements }) => render((
                            
                        <div className="stripe-form">

                            <div className="form-group" style={{ minWidth: 250 }}>
                                <label>Name on Card</label>
                                <input
                                    type="text"
                                    value={name || ''}
                                    onChange={e => this.setState({ name: e.target.value })}
                                    disabled={isDisabled}
                                />
                            </div>

                            <div className="form-group" style={{ minWidth: 250 }}>
                                <label>Card Number</label>
                                <div className="stripe-wrapper">
                                    <CardNumberElement
                                        options={this.elementOptions}
                                    />
                                </div>
                            </div>

                            <div className="form-group" style={{ minWidth: 130 }}>
                                <label>Expiry Date</label>
                                <div className="stripe-wrapper">
                                    <CardExpiryElement
                                        options={this.elementOptions}
                                    />
                                </div>
                            </div>

                            <div className="form-group" style={{ minWidth: 90 }}>
                                <label>CVC</label>
                                <div className="stripe-wrapper">
                                    <CardCvcElement
                                        options={this.elementOptionsNoPlaceholder}
                                    />
                                </div>
                            </div>

                            <div className="form-group" style={{ minWidth: 110 }}>
                                <label>Postcode</label>
                                <input
                                    type="text"
                                    value={postcode || ''}
                                    onChange={e => this.setState({ postcode: e.target.value })}
                                    disabled={isDisabled}
                                />
                            </div>
                            
                        </div>
                        
                    ), e => this.submit(stripe, elements, e))}
                </ElementsConsumer>
            </Elements>
        );
    }
}

export default StripeForm;