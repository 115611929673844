// Libs
import React, { Component } from 'react';
import queryString from 'query-string';

// Services & Helpers
import GlobalStateService from 'services/GlobalStateService';
import OnlineBookingService from 'services/OnlineBookingService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import StripeForm from 'components/pages/booking/StripeForm';
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class ConfirmSubPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        const { booking } = this.props;
        const loginDetails = GlobalStateService.getValue('loginDetails');

        // Not logged in
        if (!loginDetails) {
            this.props.history.replace('/login');
        }

        // Need to confirm email address first
        else if (!loginDetails.onlineBookingAccount || !loginDetails.onlineBookingAccount.isEmailConfirmed) {
            const qs = queryString.parse(window.location.search);
            this.setState({
                confirmEmailToken: qs.confirmEmailToken
            }, () =>
                this.checkConfirmEmailToken()
            );
        }

        // Booking a patch test
        else if (booking.isPatchTest) {
            this.props.goNext();
        }

        // No services
        else if (booking.services.length == 0 && booking.packages.length == 0) {
            this.props.history.replace('/');
        }
    }

    updateConfirmEmailToken(confirmEmailToken) {
        const allowedLetters = 'ABCDEFGHJKLMNPQRSTUVWXYZ123456789';
        confirmEmailToken = (confirmEmailToken || '').toUpperCase().split('').filter(c => allowedLetters.indexOf(c) != -1).join('');
        this.setState({
            confirmEmailToken
        }, () =>
            this.checkConfirmEmailToken()
        );
    }

    async checkConfirmEmailToken() {
        const { confirmEmailToken } = this.state;
        if (confirmEmailToken && confirmEmailToken.length >= 4) {
            this.setState({ isCheckingConfirmEmailToken: true });
            const { isConfirmed } = await OnlineBookingService.confirmEmail(confirmEmailToken);
            if (isConfirmed) {
                this.props.load(true);
            } else {
                this.setState({
                    confirmEmailError: 'That code doesn\'t seem to be valid. Please check that you\'ve entered it correctly.'
                });
            }
        } else {
            this.setState({ confirmEmailError: null });
        }
        this.setState({ isCheckingConfirmEmailToken: false });
    }

    async resendConfirmEmail() {
        this.setState({ isLoading: true });
        await OnlineBookingService.resendConfirmEmail();
        this.setState({ isLoading: false });
        BootboxHelper.alert('The email has been re-sent. If you still haven\'t received it in the next 5 minutes, please contact the salon.');
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const { salonInfo, booking } = this.props;
        const { isLoading } = this.state;
        const loginDetails = GlobalStateService.getValue('loginDetails');

        if (isLoading || !loginDetails) {
            return (
                <div className="panel">
                    <div className="please-wait">
                        <Loader />
                    </div>
                </div>
            );
        }

        if (!loginDetails.onlineBookingAccount || !loginDetails.onlineBookingAccount.isEmailConfirmed) {
            return this.renderConfirmEmail();
        }
        else if (salonInfo.enableDeposits && booking && !booking.isPatchTest && booking.takeDeposit && booking.depositAmount > 0) {
            return this.renderTakeDeposit();
        } else {
            return this.renderInner();
        }
    }

    renderConfirmEmail() {
        const { confirmEmailToken, isCheckingConfirmEmailToken, confirmEmailError } = this.state;
        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (<>
            <div className="panel">

                <div className="confirm-email">

                    {/*<p>
                        You need to confirm your email address before submitting this booking.
                    </p>*/}
                    <p>
                        We've sent an email to <b>{loginDetails.onlineBookingAccount.newEmail}</b> containing a code.
                    </p>
                    <p>
                        Please enter the code here in order to confirm your email address:
                    </p>

                    <div className="confirm-email-token-container">
                        <input
                            className="form-control"
                            value={confirmEmailToken}
                            onChange={e => this.updateConfirmEmailToken(e.target.value)}
                            required
                            disabled={isCheckingConfirmEmailToken}
                            maxLength="4"
                        />
                        {isCheckingConfirmEmailToken &&
                            <Loader isInline />
                        }
                        {confirmEmailError &&
                            <div className="alert alert-danger">
                                {confirmEmailError}
                            </div>
                        }
                    </div>

                    <p>
                        Haven't received the email? Please wait 5 minutes and check your junk folder.
                    </p>
                    <div className="resend-email-token">
                        Still haven't received it?

                        <button className="button secondary resend-confirm-email-btn" href="#" onClick={e => { e.preventDefault(); this.resendConfirmEmail() }} disabled={isCheckingConfirmEmailToken}>
                            Send it again
                        </button>
                    </div>

                </div>

            </div>

        </>);
    }

    renderTakeDeposit() {
        const { salonInfo, booking } = this.props;
        const { acceptTermsConditions, isBusy } = this.state;
        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (
            <StripeForm
                booking={booking}
                isDisabled={isBusy}
                customer={loginDetails.customer}
                stripePublicKey={salonInfo.stripePublicKey}
                onError={(error) => {
                    BootboxHelper.alert(error);
                }}
                acceptTermsConditions={acceptTermsConditions}
                setIsBusy={(isBusy) => {
                    this.setState({ isBusy })
                    this.props.setIsBusy(isBusy);
                }}
                onComplete={() => {
                    this.props.goNext();
                }}
                render={(cardDetails, submit) => <>

                    <div className="panel deposit-payment">

                        <div className="panel-header bg-colour-3">
                            Deposit Payment
                        </div>

                        <div className="panel-body">

                            {salonInfo.depositPageMessage &&
                                <p className="deposit-message">
                                    {salonInfo.depositPageMessage}
                                </p>
                            }

                            <p className="please-enter-card-details">
                                Please enter your card details below:
                            </p>

                            {cardDetails}

                            <p className="agree-terms-conditions">
                                <input
                                    id="agree-tcs"
                                    type="checkbox"
                                    checked={acceptTermsConditions || false}
                                    onChange={e => this.setState({ acceptTermsConditions: e.target.checked })}
                                />
                                <span>
                                    <label htmlFor="agree-tcs">
                                        I agree to the
                                    </label>
                                    {' '}<a href={salonInfo.termsConditionsLink} target="_blank">T&Cs</a>
                                </span>
                            </p>

                            {isBusy &&
                                <div className="submitting">
                                    <Loader isInline />
                                    <p>Please wait while we process your payment.</p>
                                    <p>Please don't refresh or close this screen.</p>
                                </div>
                            }

                        </div>

                    </div>

                    <button
                        type="button"
                        className="button primary confirm-booking-button"
                        style={{ width: '100%', fontSize: 26, marginTop: 0 }}
                        onClick={isBusy ? null : submit}
                        disabled={isBusy}
                    >
                        {isBusy && <><Loader isInline />{' '}</>}
                        Confirm Request
                    </button>

                </>}
            />
        );
    }

    renderInner() {
        const loginDetails = GlobalStateService.getValue('loginDetails');
        
        return (
            <div className="panel">

                <div className="confirm-booking">

                    <p>
                        Just one last step! Please check that you're happy with everything<br />
                        in the appointment summary before submitting your request.
                    </p>

                    <button className="button primary confirm-booking-button" onClick={e => this.props.goNext()}>
                        Confirm request
                    </button>

                    <p className="login-info">
                        You are logged in as {loginDetails.customer.firstName} {loginDetails.customer.lastName}
                    </p>

                </div>

            </div>
        );
    }
}

export default ConfirmSubPage;
