// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { ContextMenuTrigger, ContextMenu, MenuItem, connectMenu } from 'react-contextmenu';
import { showMenu } from 'react-contextmenu/modules/actions'
import moment from 'moment';
import App from 'App';

// Services & Helpers
import OnlineBookingService from 'services/OnlineBookingService';
import GlobalStateService from 'services/GlobalStateService';
import TextHelpers from 'helpers/TextHelpers';
import DateHelpers from 'helpers/DateHelpers';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import Loader from 'components/reusable/Loader';
import SelectServicesSubPage from 'components/pages/booking/SelectServicesSubPage';
import SelectDateSubPage from 'components/pages/booking/SelectDateSubPage';
import LogInSubPage from 'components/pages/booking/LogInSubPage';
import ConfirmSubPage from 'components/pages/booking/ConfirmSubPage';
import SubmitSubPage from 'components/pages/booking/SubmitSubPage';
import SubmittedSubPage from 'components/pages/booking/SubmittedSubPage';

//-------------------------------------------------------------------------------------------------------------------

let AccountDropDown = (props) => {
    const { id, parent } = props;
    const loginDetails = GlobalStateService.getValue('loginDetails');

    return ((<ContextMenu id={id}>
        {loginDetails && <>
            <MenuItem onClick={(e) => parent.props.history.push('/account/')}>
                Account
            </MenuItem>
            <MenuItem onClick={(e) => parent.logOut()}>
                Log out
            </MenuItem>
        </>}
        {!loginDetails && <>
            <MenuItem onClick={(e) => parent.props.history.push('/account/')}>
                Log in
            </MenuItem>
            <MenuItem onClick={(e) => parent.props.history.push('/account/register')}>
                Register
            </MenuItem>
        </>}
    </ContextMenu>));
}
AccountDropDown = connectMenu('account-drop-down')(AccountDropDown);

class BookingPage extends Component {

    constructor(props) {
        super(props);

        // Bind event handlers
        this.addOrUpdateService = this.addOrUpdateService.bind(this);
        this.removePackage = this.removePackage.bind(this);
        this.removeService = this.removeService.bind(this);
        this.updateBooking = this.updateBooking.bind(this);
        this.removeSpecificBookingInfo = this.removeSpecificBookingInfo.bind(this);
        this.restoreOriginalBooking = this.restoreOriginalBooking.bind(this);
        this.load = this.load.bind(this);
        this.goTo = this.goTo.bind(this);
        this.goNext = this.goNext.bind(this);
        this.goPrev = this.goPrev.bind(this);
        this.updateLoginDetails = this.updateLoginDetails.bind(this);
        this.updateBookingServiceFields = this.updateBookingServiceFields.bind(this);

        // Init
        this.subPageRef = React.createRef();
        this.salonCode = GlobalStateService.getValue('salonCode');
        const loginDetails = OnlineBookingService.getLocalStorage('login-' + this.salonCode);
        this.updateLoginDetails(loginDetails);
        this.state = {
            isLoading: true,
            promoCodeID: null,
            isPromoFound:true
        };
    }

    updateBookingServiceFields(serviceID, newValues) {
        const booking = { ...this.state.booking };
        booking.services = [...booking.services];
        const index = booking.services.findIndex(s => s.serviceID == serviceID);
        if (index != -1) {
            for (var field in newValues) {
                booking.services[index][field] = newValues[field];
            }
            this.updateBooking(booking);
        }
    }

    updateBookingPackageFields(packageID, newValues) {
        const booking = { ...this.state.booking };
        booking.packages = [...booking.packages];
        const index = booking.packages.findIndex(p => p.packageID == packageID);
        if (index != -1) {
            for (var field in newValues) {
                booking.packages[index][field] = newValues[field];
            }
            this.updateBooking(booking);
        }
    }
    //--------------------------------------------------------------------------------------------------------------------

    componentDidMount() {
        // Initialise booking. Load from local storage if available
        let booking = OnlineBookingService.getLocalStorage('booking-' + this.salonCode, {});

        // If on an old non-versioned booking info, or if this is an old (submitted) booking, delete it and start again
        let isBookingChanged = false;
        if ((booking && !booking.version) || booking.isSubmitted) {
            booking = {
                version: OnlineBookingService.Version
            };
            isBookingChanged = true;
        }
        if (!booking.services) {
            booking.services = [];
            isBookingChanged = true;
        }
        if (!booking.packages) {
            booking.packages = [];
            isBookingChanged = true;
        }

        if (isBookingChanged) {
            OnlineBookingService.setLocalStorage('booking-' + this.salonCode, booking);
        }

        // Update and trigger load
        this.setState({
            booking
        }, () => {
            this.load(false);
        });
    }

    componentDidUpdate(oldProps) {
        if ((this.props.match.params.subPage || '') != (oldProps.match.params.subPage || '')) {
            this.checkBookingValidity();
            this.setState({ isBusy: false });
        }
    }

    async load(silent) {
        if (!silent) {
            this.setState({ isLoading: true });
        }

        // If the payment was already made, skip payment
        const isPaymentAlreadyMade = await OnlineBookingService.isPaymentAlreadyMade();
        if (isPaymentAlreadyMade) {
            this.goTo('confirm');
        }

        // Check login
        const loginInfo = await OnlineBookingService.checkLogin();
        this.updateLoginDetails(loginInfo);

        // Get patch test date if not known
        let { latestPatchTestDate, nextPatchTestDate } = this.state;
        if (loginInfo && loginInfo.isLoggedIn) {
            if (!latestPatchTestDate) {
                latestPatchTestDate = await OnlineBookingService.getLatestPatchTestDate();
            }
            if (!nextPatchTestDate) {
                nextPatchTestDate = await OnlineBookingService.getNextPatchTestDate();
            }
        } else {
            latestPatchTestDate = null;
            nextPatchTestDate = null;
        }

        // Get other info
        const serviceCategories = await OnlineBookingService.listServicesByCategory();
        const promoCodes = await OnlineBookingService.getPromoCodesList();

        // Update UI
        this.setState({
            isLoading: false,
            isBusy: false,
            serviceCategories,
            latestPatchTestDate,
            nextPatchTestDate,
            promoCodes,
            promoCodeID: null,
            promoCode: '',
            isCodeValidated: false
        });
    }

    updateLoginDetails(loginDetails) {
        if (loginDetails && !loginDetails.customer) {
            loginDetails = null;
        }
        GlobalStateService.setValue('loginDetails', loginDetails);
        OnlineBookingService.setLocalStorage('login-' + this.salonCode, loginDetails);
    }

    checkBookingValidity() {
        const booking = { ...this.state.booking };
        if (booking.isSubmitted) {
            this.startAgain();
        }
    }

    async updateBooking(booking, callback) {
        // Calculate total
        booking.minPrice = 0;
        booking.maxPrice = 0;
        booking.services.forEach(bs => {
            booking.minPrice += (bs.minPrice || 0);
            booking.maxPrice += (bs.maxPrice || 0);
        });
        booking.packages.forEach(bs => {
            booking.minPrice += (bs.minPrice || 0);
            booking.maxPrice += (bs.maxPrice || 0);
        });

        // Update on UI
        this.setState({
            booking
        }, () => {
            if (!booking.isCompleted) {
                this.loadSpecificBookingInfo();
            }
            if (callback) {
                callback();
            }
        });

        // Save to local storage
        OnlineBookingService.setLocalStorage(`booking-${this.salonCode}`, booking);
    }

    async loadSpecificBookingInfo() {
        let booking = this.state.booking;

        // Get specific booking information
        if (booking.date && booking.time) {
            const hasChanged = (booking.date != booking.oldDate || booking.time != booking.oldTime || !booking.isSpecific || typeof (booking.oldNumServices) == 'undefined' || booking.services.length != booking.oldNumServices || booking.packages.length != booking.oldNumPackages || this.state.isPromoCodeModified);
            if (hasChanged) {

                booking.promoCodeID = this.state.promoCodeID;
                const specificBookingInfo = await OnlineBookingService.getSpecificBookingInfo(booking);
                this.setState({
                    promoCodeAmount: specificBookingInfo.promoDiscountAmount
                })
                // Load pricing information
                booking = { ...this.state.booking };
                booking.isSpecific = true;
                booking.specificPrice = specificBookingInfo.total;
                booking.takeDeposit = specificBookingInfo.takeDeposit;
                booking.depositAmount = specificBookingInfo.depositAmount;
                booking.oldDate = booking.date;
                booking.oldTime = booking.time;
                booking.oldNumServices = booking.services.length;
                booking.oldNumPackages = booking.packages.length;

                // bookingPackage.specificPrice

                // Load service information
                for (let i = 0; i < specificBookingInfo.services.length; i++) {
                    const specificServiceInfo = specificBookingInfo.services[i];
                    let service = booking.services.find(s => s.service.serviceID == specificServiceInfo.service.serviceID);

                    // Look for the service in a package instead
                    if (!service) {
                        if (specificServiceInfo.packageID) {
                            const pkg = booking.packages.find(p => p.packageID == specificServiceInfo.packageID);
                            if (pkg) {
                                service = pkg.services.find(s => s.serviceID == specificServiceInfo.service.serviceID);
                            }
                        }
                    }

                    // If found, load info
                    if (service) {
                        service.specificPrice = specificServiceInfo.price;
                        service.specificStylist = specificServiceInfo.stylist;
                    }
                }

                // Load package information
                for (let i = 0; i < specificBookingInfo.packages.length; i++) {
                    const specificPackageInfo = specificBookingInfo.packages[i];
                    let pkg = booking.packages.find(p => p.packageID == specificPackageInfo.packageID);
                    if (pkg) {
                        pkg.specificPrice = specificPackageInfo.price;
                    }
                }
            }
        } else {
            delete booking.oldDate;
            delete booking.oldTime;
            delete booking.oldNumServies;
            delete booking.isSpecific;
            delete booking.takeDeposit;
            delete booking.depositAmount;
            booking.services.forEach(s => {
                delete s.specificPrice;
                delete s.specificStylist;
            });
            booking.packages.forEach(p => {
                delete p.specificPrice;
            });
        }

        // Update on UI
        this.setState({ booking });

        // Save to local storage
        OnlineBookingService.setLocalStorage(`booking-${this.salonCode}`, booking);
    }

    removeSpecificBookingInfo() {
        const booking = { ...this.state.booking };
        if (!booking.isSpecific) {
            return;
        }
        delete booking.isSpecific;
        delete booking.depositAmount;
        booking.services.forEach(s => {
            delete s.specificPrice;
            delete s.specificStylist;
        });
        booking.packages.forEach(p => {
            delete p.specificPrice;
        });
        this.setState({ booking });

        // Save to local storage
        OnlineBookingService.setLocalStorage(`booking-${this.salonCode}`, booking);
    }

    goTo(subPage) {
        this.props.history.push('/' + subPage);
        App.scrollTo(0, 0);
    }

    goNext() {
        const loginDetails = GlobalStateService.getValue('loginDetails');

        let nextSubPage = null;
        switch (this.props.match.params.subPage || '') {
            case '':
                nextSubPage = 'select-date';
                break;
            case 'select-date':
                if (loginDetails) {
                    nextSubPage = 'confirm';
                } else {
                    nextSubPage = 'login';
                }
                break;
            case 'login':
                nextSubPage = 'confirm';
                break;
            case 'confirm':
                nextSubPage = 'submit';
                break;
            case 'submit':
                nextSubPage = 'submitted';
                break;
        }
        if (nextSubPage !== null) {
            this.props.history.push('/' + nextSubPage);
        }
        App.scrollTo(0, 0);
    }

    goPrev() {
        const loginDetails = GlobalStateService.getValue('loginDetails');

        let prevSubPage = null;
        switch (this.props.match.params.subPage || '') {
            case 'select-date':
                prevSubPage = '';
                break;
            case 'login':
            case 'submit':
                prevSubPage = 'select-date';
                break;
            case 'confirm':
                if (loginDetails) {
                    prevSubPage = 'select-date';
                } else {
                    prevSubPage = 'login';
                }
                break;
        }
        if (prevSubPage !== null) {
            this.props.history.push('/' + prevSubPage);
        }
        App.scrollTo(0, 0);
    }

    restoreOriginalBooking(callback) {
        const booking = { ...this.state.booking };
        if (booking.isPatchTest) {
            delete booking.isPatchTest;
            delete booking.isSubmitted;
            booking.services = booking.oldServices;
            booking.packages = booking.oldPackages;
            delete booking.oldServices;
            delete booking.oldPackages;
            this.updateBooking(booking, () => {
                this.props.history.push('');
                this.load(false);
                if (callback) {
                    callback();
                }
            });
        } else if (callback) {
            callback();
        }
    }

    startAgain() {
        // If we're booking a patch test, swap services out for the originals
        const booking = { ...this.state.booking };
        if (booking.isPatchTest) {
            this.restoreOriginalBooking();
        } else {
            // Otherwise just do a normal reset
            this.setState({
                booking: {
                    services: [],
                    packages: []
                }
            }, () => {
                this.props.history.push('');
                this.load(false);
            });
        }
    }

    removeService(service) {
        const booking = this.state.booking;
        const promoCode = this.state.promoCode;
        for (var i = 0; i < booking.services.length; i++) {
            const bookingService = booking.services[i];
            if (bookingService.serviceID == service.serviceID) {
                booking.services.splice(i, 1);
                break;
            }
        }
        if (!booking.services.some(s => s.service.requiresPatchTest) && !booking.packages.some(p => p.services.some(s => s.service.requiresPatchTest))) {
            delete booking.isPatchTestConfirmed;
        }
        if (booking.isPatchTest && !booking.services.some(s => s.service.isPatchTest)) {
            delete booking.isPatchTest;
        }
        if (booking.services.length == 0 && booking.packages.length == 0) {
            booking.date = null;
            booking.time = null;
        }
        this.updateBooking(booking, async() => {
            if (booking.services.length == 0) {
                this.props.history.replace('');
                return;
            }
            if (promoCode) {
                await this.applyCode();
            }

            switch (this.props.match.params.subPage) {
                case 'confirm':
                case 'submit':
                case 'submitted':
                    this.props.history.replace('/booking/select-date');
                    break;
            }
        });
    }

    removePackage(pkg) {
        const booking = this.state.booking;
        for (var i = 0; i < booking.packages.length; i++) {
            const bookingPackage = booking.packages[i];
            if (bookingPackage.packageID == pkg.packageID) {
                booking.packages.splice(i, 1);
                break;
            }
        }
        if (!booking.packages.some(p => p.requiresPatchTest)) {
            delete booking.isPatchTestConfirmed;
        }
        if (booking.isPatchTest) {
            let packageServices = booking.packages.find(p => p.services);
            for (let i = 0; i < packageServices.length; i++) {
                if (!packageServices[i].some(s => s.service.isPatchTest)) {
                    delete booking.isPatchTest;
                }
            }
        }
        if (booking.services.length == 0 && booking.packages.length == 0) {
            booking.date = null;
            booking.time = null;
        }
        this.updateBooking(booking, () => {
            if (booking.services.length == 0 && booking.packages.length == 0) {
                this.props.history.replace('');
                return;
            }

            switch (this.props.match.params.subPage) {
                case 'confirm':
                case 'submit':
                case 'submitted':
                    this.props.history.replace('/select-date');
                    break;
            }
        });
    }

    getHasErrors() {
        const booking = this.state.booking;
        if (!booking) return true;
        const subPage = (this.props.match.params.subPage || '');
        switch (subPage) {
            case '':
                if (booking.services.length == 0 && booking.packages.length == 0) {
                    return true;
                }

                if (!booking.isPatchTestConfirmed
                    && (
                        booking.services.some(s => s.service.requiresPatchTest)
                        ||
                        booking.packages.some(p => p.services.some(s => s.service.requiresPatchTest))
                    )
                    && !this.state.latestPatchTestDate
                    && !this.state.nextPatchTestDate
                ) {
                    return true;
                }
                break;
            case 'select-date':
                return !booking.date || !booking.time;
        }
        return false;
    }

    async confirmPatchTest() {
        const booking = { ...this.state.booking };
        booking.isPatchTestConfirmed = true;
        booking.isPatchTest = false;
        this.updateBooking(booking);
    }

    async bookPatchTest() {
        const booking = { ...this.state.booking };
        const {
            serviceCategories
        } = this.state;
        booking.isPatchTestConfirmed = false;
        booking.isPatchTest = true;
        booking.oldServices = booking.services;
        booking.oldPackages = booking.packages;

        this.updateBooking(booking, () => {

            // Add patch test
            for (var i = 0; i < serviceCategories.length; i++) {
                const serviceCategory = serviceCategories[i];
                for (var j = 0; j < serviceCategory.services.length; j++) {
                    const service = serviceCategory.services[j];
                    if (service.isPatchTest) {
                        this.addOrUpdateService(service, true, () => {
                            this.goNext();
                        });
                        return;
                    }
                }
            }

            // Patch test not found!
            BootboxHelper.alert('Sorry, this salon has not set up patch tests yet');

        });
    }

    async addOrUpdateService(service, clearExisting, callback) {
        debugger;
        const {
            serviceCategories
        } = this.state;
        const booking = { ...this.state.booking };
        booking.services = (clearExisting ? [] : [...booking.services]);
        booking.packages = (clearExisting ? [] : [...booking.packages]);

        let packageServices = booking.packages.map(p => p.serviceIDs);
        for (let i = 0; i < packageServices.length; i++) {
            if (packageServices[i].includes(service.serviceID))
                BootboxHelper.alert('Please note the service <b>' + service.name + '</b> already included in the package');
        }

        // Update existing service - just change the stylist
        const isAlreadySelected = (booking.services.findIndex(s => s.serviceID == service.serviceID) != -1);
        const isPackageAlreadyAdded = (booking.packages.findIndex(s => s.packageID == service.packageID) != -1);
        if (isAlreadySelected) {
            const pricingInfo = this.getPricingInfo(service, service.stylist);
            this.updateBookingServiceFields(service.serviceID, {
                stylist: service.stylist,
                minPrice: pricingInfo.minPrice,
                maxPrice: pricingInfo.maxPrice
            });
        }
        else if (isPackageAlreadyAdded) {
            this.updateBookingPackageFields(service.packageID, {
                minPrice: service.minPrice,
                maxPrice: service.maxPrice
            });
        }
        // Sanity check
        else if (booking.services.length >= 8) {
            BootboxHelper.alert('Sorry, you cannot select more than 8 services in a single booking.');
        }
        else if (service.packageID) {
            // Add package to booking
            let bookingPackage;

            bookingPackage = {
                name: service.name,
                packageID: service.packageID,
                minPrice: service.minPrice,
                maxPrice: service.maxPrice,
                pricingType: service.pricingType,
                isPriceOnConsultation: service.isPriceOnConsultation,
                serviceIDs: service.serviceIDs,
                requiresPatchTest: service.requiresPatchTest
            };
            bookingPackage.services = [];
            service.services.forEach(s => {
                bookingPackage.services.push({
                    serviceID: s.serviceID,
                    service: {
                        serviceID: s.serviceID,
                        name: s.name,
                        requiresPatchTest: s.requiresPatchTest,
                        isPriceOnConsultation: s.isPriceOnConsultation
                    },
                    stylist: s.stylist
                });
            })
            booking.packages.push(bookingPackage);
            this.updateBooking(booking, callback);
        }
        else {
            // Add service to booking
            const byStylist = service.byStylist[service.stylist?.userID];
            if (!byStylist) {
                service.stylist = null;
            }
            const pricingInfo = this.getPricingInfo(service, service.stylist);
            booking.services.push({
                serviceID: service.serviceID,
                service: {
                    serviceID: service.serviceID,
                    name: service.name,
                    requiresPatchTest: service.requiresPatchTest,
                    isPriceOnConsultation: service.isPriceOnConsultation
                },
                stylist: service.stylist,
                minPrice: pricingInfo.minPrice,
                maxPrice: pricingInfo.maxPrice
            });

            // Update UI
            this.updateBooking(booking, callback);

            // Show a popup note if there is one
            for (let i = 0; i < serviceCategories.length; i++) {
                const serviceCategory = serviceCategories[i];
                if (serviceCategory.bookingPopupNote && service.serviceCategoryIDs.indexOf(serviceCategory.serviceCategoryID) != -1) {
                    await BootboxHelper.alert(serviceCategory.bookingPopupNote);
                }
            }

        }
    }

    getPricingInfo(service, stylist) {
        if (service.isPriceOnConsultation) {
            return 0;
        }
        if (stylist) {
            const byStylist = service.byStylist[stylist.userID];
            return {
                minPrice: byStylist.price,
                maxPrice: byStylist.price
            };
        } else {
            return {
                minPrice: service.minPrice,
                maxPrice: service.maxPrice
            };
        }
    }

    logOut() {
        const salonCode = GlobalStateService.getValue('salonCode');
        localStorage.removeItem('login-' + salonCode);
        GlobalStateService.setValue('loginDetails', null);
        this.props.history.push('');
        this.load(true);
    }

    showContextMenu(e, event) {
        const x = (e.touches ? e.touches[0].clientX : e.clientX);
        const y = (e.touches ? e.touches[0].clientY : e.clientY);

        showMenu({
            position: { x: x - 130, y: y },
            target: e.target,
            id: 'account-drop-down'
        });
    }

    async redirectToSalon() {
        var value = await OnlineBookingService.getSalonGroupCode();
        window.location.replace('/group/' + value);
    }

    changePromoCode(value) {
        this.setState({
            promoCode: value,
            promoCodeID: null,
            isPromoCodeModified:true
        })
    }

    async applyCode() {
        this.setState({
            isValidatingCode: true,
            isCodeValidated: false
        })
        const { booking, promoCode, promoCodes } = this.state;
        var canApplyPromo = false;
        var promoCodeModel = await OnlineBookingService.applyPromoCode(promoCode, booking);

        if (promoCodeModel) {
            var promo = promoCodes.find(ap => ap.code.toLowerCase() == promoCode.toLowerCase());
            if (promo) {
                if (booking.packages) {
                    booking.packages.forEach(ap => {
                        ap.services.forEach(as => {
                            if (promo.promoServiceApplicability == 'selected') {
                                as.canApplyPromo = promo.servicePromoCodeIDs.includes(as.serviceID) && promo.useForPackage;
                            }
                            else if (promo.promoServiceApplicability == 'all') {
                                as.canApplyPromo = true && promo.useForPackage;
                            }
                        })
                    });
                }

                if (booking.services) {
                    booking.services.forEach(asv => {
                        if (promo.promoServiceApplicability == 'selected') {
                            asv.canApplyPromo = promo.servicePromoCodeIDs.includes(asv.serviceID);
                        }
                        else if (promo.promoServiceApplicability == 'all') {
                            asv.canApplyPromo = true;
                        }
                    });
                }
                canApplyPromo = booking.services.some(as => as.canApplyPromo) || booking.packages.some(ap => ap.services.some(as => as.canApplyPromo));
            }
        }

        if (promoCodeModel) {
            if (canApplyPromo) {
                this.setState({
                    promoCodeModel,
                    isPromoCodeModified: true,
                    isPromoFound: true,
                    promoCodeID: promoCodeModel.promoCodeID,
                    canApplyPromo: true
                });
            }
            else {
                this.setState({
                    isPromoCodeModified: false,
                    isPromoFound: true,
                    promoCodeID: null,
                    canApplyPromo: false
                })
            }
        }
        else {
            this.setState({
                promoCode: '',
                isPromoCodeModified: true,
                promoCodeID: null,
                isPromoFound: false,
                canApplyPromo: true
            })
        }
        await this.loadSpecificBookingInfo();

        this.setState({
            isValidatingCode: false,
            isCodeValidated: true
        });
    }

    removePromoCode() {
        this.setState({
            promoCode: '',
            promoCodeID: null,
            isPromoCodeModified: true
        }, () => { this.loadSpecificBookingInfo() }) ;
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const { salonInfo } = this.props;
        const { isBusy } = this.state;
        const booking = this.state.booking;
        const subPage = (this.props.match.params.subPage || '');
        const loginDetails = GlobalStateService.getValue('loginDetails');
        let canGoNext = !isBusy && (subPage != 'login' && subPage != 'submit' && subPage != 'submitted') && !this.getHasErrors();
        const canGoBack = !isBusy && (subPage != 'submit' && subPage != 'submitted' && subPage != '');
        let nextButtonContent = 'Next';

        if (subPage == 'confirm') {
            if (salonInfo.enableDeposits || !loginDetails || !loginDetails.onlineBookingAccount || !loginDetails.onlineBookingAccount.isEmailConfirmed) {
                canGoNext = false;
            } else {
                nextButtonContent = 'Confirm request';
            }
        } else if (subPage == 'select-date' && booking && booking.isPatchTest) {
            nextButtonContent = 'Confirm patch test';
        }

        return (
            <div>

                <div className="page-content-top">
                    <div className="panel summary-panel log-in-container">
                        <div className="panel-header log-in-bar" onClick={e => this.setState({ accExpand: !this.state.accExpand })}>
                            <span className="fa fa-user log-in-left"></span>
                            {!loginDetails && <span className="log-in-centre">Log In</span>}
                            {loginDetails && <span className="log-in-centre">{loginDetails.customer.firstName}</span>}
                            {!this.state.accExpand && <span className="fa fa-angle-down log-in-right"></span>}
                            {this.state.accExpand && <span className="fa fa-angle-up log-in-right"></span>}
                        </div>

                        {this.state.accExpand && <div className="panel-content">
                            {loginDetails && <>
                                {loginDetails.customer.firstName} {loginDetails.customer.lastName}
                                <MenuItem divider/>
                                <MenuItem onClick={(e) => this.props.history.push('/account/')}>
                                    Account
                                </MenuItem>
                                <MenuItem onClick={(e) => this.logOut()}>
                                    Log out
                                </MenuItem>
                            </>}
                            {!loginDetails && <>
                                <MenuItem onClick={(e) => this.props.history.push('/account/')}>
                                    Log in
                                </MenuItem>
                                <MenuItem onClick={(e) => this.props.history.push('/account/register')}>
                                    Register
                                </MenuItem>
                            </>}
                        </div>}
                    </div>
                </div>

                <div className="page-content">

                    <div className="page-content-left">

                        <div className="panel steps-panel">

                            <div className="panel-body">

                                {this.renderSteps()}

                            </div>

                        </div>

                        {this.renderMain()}

                    </div>


                    <div className="page-content-right">
                        {salonInfo.salonGroupID &&
                            <>
                                <div className="panel summary-panel">
                                    <div className="panel-header">
                                        Selected Salon
                                    </div>
                                    <div className="panel-body booking-summary">
                                        <div className="salon-info">
                                            <label className="salon-name-label">{salonInfo.name}</label>
                                            <button className="button primary change-button" onClick={() => this.redirectToSalon()}>Change</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="page-content-right-inner">

                            {/* Summary panel */}
                            <div className="panel summary-panel">

                                <div className="panel-header">
                                    Summary
                                </div>

                                <div className="panel-body booking-summary">

                                    {this.renderSummary()}

                                </div>

                            </div>

                            {/* Next/Back/Start again buttons */}
                            {canGoNext &&
                                <button className="button primary" onClick={e => this.goNext()}>
                                    {nextButtonContent}{' '}
                                    <span className="fa fa-chevron-right"></span>
                                </button>
                            }

                            {canGoBack &&
                                <button className="button secondary" onClick={e => this.goPrev()}>
                                    <span className="fa fa-chevron-left"></span>
                                    {' '}Back
                                </button>
                            }

                            {subPage == 'submitted' &&
                                <>
                                    <button className="button secondary" onClick={e => this.startAgain()}>
                                        <span className="fa fa-redo"></span>{' '}
                                        Book another
                                    </button>
                                </>
                            }
                        </div>

                    </div>

                </div>
            </div>

        );

    }

    renderMain() {
        const {
            booking,
            isLoading,
            serviceCategories
        } = this.state;
        const {
            salonCode,
            salonInfo
        } = this.props;

        if (isLoading) {
            return (
                <div className="panel">
                    <div className="panel-body">
                        <Loader />
                    </div>
                </div>
            );
        }

        const subProps = {
            history: this.props.history,
            param: this.props.match.params.subPageParam,
            salonCode,
            salonInfo,
            booking,
            updateBooking: this.updateBooking,
            removePackage: this.removePackage,
            removeService: this.removeService,
            removeSpecificBookingInfo: this.removeSpecificBookingInfo,
            restoreOriginalBooking: this.restoreOriginalBooking,
            updateLoginDetails: this.updateLoginDetails,
            markBookingCompleted: this.markBookingCompleted,
            goTo: this.goTo,
            goNext: this.goNext,
            goPrev: this.goPrev,
            load: this.load
        };

        // Decide which sub-page to show
        switch (this.props.match.params.subPage || '') {
            case '':
                return (
                    <SelectServicesSubPage
                        ref={this.subPageRef}
                        serviceCategories={serviceCategories}
                        addOrUpdateService={this.addOrUpdateService}
                        onLoad={() => this.load(true)}
                        {...subProps}
                    />
                );
            case 'select-date':
                return (
                    <SelectDateSubPage ref={this.subPageRef} {...subProps} />
                );
            case 'login':
                return (
                    <LogInSubPage ref={this.subPageRef} {...subProps} />
                );
            case 'confirm':
                return (
                    <ConfirmSubPage
                        ref={this.subPageRef}
                        setIsBusy={isBusy => this.setState({ isBusy })}
                        {...subProps}
                    />
                );
            case 'submit':
                return (
                    <SubmitSubPage ref={this.subPageRef} {...subProps} />
                );
            case 'submitted':
                return (
                    <SubmittedSubPage ref={this.subPageRef} {...subProps} />
                );
            default:
                return (<>...</>);
        }
    }

    renderSteps() {
        const { salonInfo } = this.props;
        const { booking } = this.state;

        let stepNum = 0;
        switch (this.props.match.params.subPage || '') {
            case 'submitted':
                stepNum = 5;
                break;
            case 'confirm':
            case 'submit':
                stepNum = 4;
                break;
            case 'login':
                stepNum = 3;
                break;
            case 'select-date':
                stepNum = 2;
                break;
            case '':
                stepNum = 1;
                break;
        }

        return (
            <ul>
                <li className={"completed" + (stepNum === 1 ? " current" : '')} onClick={() => this.goTo('')}>
                    <span className="name">1. Services</span>
                </li>
                <li className={(stepNum >= 2 ? 'completed' : '') + (stepNum === 2 ? " current" : '')} onClick={() => stepNum >= 2 ? this.goTo('select-date') : null}>
                    <span className="name">2. Date &amp; Time</span>
                </li>
                <li className={(stepNum >= 3 ? 'completed' : '') + (stepNum === 3 ? " current" : '')} onClick={() => stepNum >= 3 ? this.goTo('login') : null}>
                    <span className="name">3. Log in</span>
                </li>
                <li className={(stepNum >= 4 ? 'completed' : '') + (stepNum === 4 ? " current" : '')} onClick={() => stepNum >= 4 ? this.goTo('confirm') : null}>
                    <span className="name">
                        {(salonInfo.enableDeposits && booking && !booking.isPatchTest && booking.depositAmount > 0) ?
                            <>4. Deposit Payment</> :
                            <>4. Confirm</>
                        }
                    </span>
                </li>
                <li className={(stepNum >= 5 ? 'completed' : '') + (stepNum === 5 ? " current" : '')}>
                    <span className="name">5. Done</span>
                </li>
            </ul>
        );
    }

    renderSummary() {
        const {
            isLoading,
            booking,
            latestPatchTestDate,
            nextPatchTestDate,
            promoCode,
            promoCodeID,
            promoCodeAmount,
            isPromoFound,
            isValidatingCode,
            canApplyPromo,
            isCodeValidated
        } = this.state;
        const { salonInfo } = this.props;
        const subPage = (this.props.match.params.subPage || '');
        const loginDetails = GlobalStateService.getValue('loginDetails');

        if (isLoading) {
            return (<Loader />);
        }

        const formatPrice = (minPrice, maxPrice) => {
            if (typeof (maxPrice) == 'undefined') {
                maxPrice = minPrice;
            }
            const currencyOpts = {
                numDP: 2,
                includeSymbol: true,
                currency: this.props.salonInfo.currency
            };
            if (minPrice == maxPrice) {
                return <>{TextHelpers.formatCurrency(minPrice, currencyOpts)}</>;
            } else {
                return <>From {TextHelpers.formatCurrency(minPrice, currencyOpts)}</>;
            }
        };

        if (!booking.date && ((booking.services.length == 0) && (booking.packages.length == 0))) {
            return (
                <div className="please-select-service">
                    Please select a service.
                </div>
            );
        }

        const canEdit = (subPage != 'submit' && subPage != 'submitted');
        const anyRequirePatchTest = booking.services.some(s => s.service.requiresPatchTest) ||
            booking.packages.some(p => p.services.some(s => s.service.requiresPatchTest));
        const anyPOC = booking.services.some(s => s.service.isPriceOnConsultation) || booking.packages.some(p => p.isPriceOnConsultation);

        let patchTestMessage = 'Please book in for a patch test a minimum of 48 hours before your appointment if it\'s been more than 6 months since you\'ve had a colour with us.';
        if (this.props.salonInfo.patchTestMessage) {
            patchTestMessage = this.props.salonInfo.patchTestMessage || '';
        }

        return (<>

            <p>Your Appointment:</p>

            {/* Timings */}
            {!!booking.date &&
                <div className="booking-summary-group timing-group">
                    {booking.date &&
                        <div className="booking-summary-item">
                            <span className="booking-summary-icon far fa-calendar-alt"></span>
                            {moment(booking.date).format('dddd, Do MMMM YYYY')}
                        </div>
                    }
                    {booking.time &&
                        <div className="booking-summary-item">
                            <span className="booking-summary-icon far fa-clock"></span>
                            {DateHelpers.removeSeconds(booking.time, true)}
                        </div>
                    }
                </div>
            }

            {/* Services */}
            <div className="booking-summary-group services-group">
                {booking.services.map(service =>
                    <React.Fragment key={service.serviceID}>

                        <div className="booking-summary-item">
                            {service.service.requiresPatchTest && !booking.isPatchTestConfirmed && !latestPatchTestDate && !nextPatchTestDate ?
                                <span className="booking-summary-icon fa fa-exclamation-triangle text-warning"></span> :
                                <span className="booking-summary-icon fa fa-angle-right"></span>
                            }

                            <div className="booking-summary-left">

                                {service.service.name}<br />

                                {booking.isSpecific ?
                                    (!!service.specificStylist && service.specificStylist.nickname) :
                                    <>
                                        {!!service.stylist ?
                                            service.stylist.nickname :
                                            <>Any Stylist</>
                                        }
                                    </>
                                }

                            </div>
                            <div className="booking-summary-right">
                                {
                                    service.service.isPriceOnConsultation ? 'POC' :
                                        booking.isSpecific ? formatPrice(service.specificPrice, service.specificPrice) :
                                            formatPrice(service.minPrice, service.maxPrice)
                                }

                                {canEdit &&
                                    <button className="button primary delete-service-button" onClick={e => this.removeService(service)}>
                                        <span className="fa fa-times"></span>
                                    </button>
                                }

                            </div>
                        </div>

                        {!booking.isSpecific && service.minPrice != service.maxPrice &&
                            <div className="booking-summary-item estimated-price-warning">
                                <span className="booking-summary-icon"></span>
                                <div className="booking-summary-left">
                                    This is an estimated price - please select a date and time to get an accurate price.
                                </div>
                            </div>
                        }

                    </React.Fragment>
                )}

                {booking.packages.map(bookingPackage =>
                    <React.Fragment key={bookingPackage.packageID}>

                        <div className="booking-summary-item">

                            {bookingPackage.services.some(s => s.service.requiresPatchTest) && !booking.isPatchTestConfirmed && !latestPatchTestDate && !nextPatchTestDate ?
                                <span className="booking-summary-icon fa fa-exclamation-triangle text-warning"></span> :
                                <span className="booking-summary-icon fa fa-angle-right"></span>
                            }

                            <div className="booking-summary-left">
                                {bookingPackage.name}<br />
                                {bookingPackage.services.map((service, index) => {
                                    const stylist = (bookingPackage.stylists ? bookingPackage.stylists[service.serviceID] : null);
                                    return (
                                        <div key={index} className="booking-summary-package-service">
                                            <span className="booking-summary-icon fa fa-angle-right"></span>
                                            <div>
                                                {service.service.name}<br />
                                                {booking.isSpecific ?
                                                    (!!service.specificStylist && service.specificStylist.nickname) :
                                                    <>
                                                        {!!stylist ?
                                                            stylist.nickname :
                                                            <>Any Stylist</>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="booking-summary-right">
                                {
                                    bookingPackage.isPriceOnConsultation ? 'POC' :
                                        (booking.isSpecific ?
                                            formatPrice(bookingPackage.specificPrice, bookingPackage.specificPrice) :
                                            formatPrice(bookingPackage.minPrice, bookingPackage.maxPrice)
                                        )
                                }

                                {canEdit &&
                                    <button className="button primary delete-service-button" onClick={e => this.removePackage(bookingPackage)}>
                                        <span className="fa fa-times"></span>
                                    </button>
                                }

                            </div>
                        </div>
                    </React.Fragment>
                )}

                {booking.date && booking.time && <>
                    <div className="booking-summary-item">

                        {(!promoCodeID || promoCodeID == 0) && <><div className="booking-summary-left">
                            Promo code
                        </div>
                            <div className="booking-summary-right">
                                <input type="text" disabled={isValidatingCode} value={promoCode} onChange={(e) => this.changePromoCode(e.target.value)} />
                                {promoCode && !promoCodeID &&
                                    <button className="button primary button-small apply-code-button" disabled={isValidatingCode} onClick={e => this.applyCode()}>
                                        {!isValidatingCode && <>
                                            Apply Code{' '}<span className="fa fa-check"></span>
                                        </>}
                                        {isValidatingCode && <>
                                            <Loader isInline={true} />{' '}
                                            Validating...
                                        </>}
                                    </button>
                                }
                            </div>
                        </>}
                        {(promoCodeID > 0) && <>
                            <span className="booking-summary-icon fa fa-angle-right"></span>
                            <div className="booking-summary-left">
                            {promoCode}
                        </div>
                            {promoCodeAmount > 0 && 
                                <div className="booking-summary-right">-{formatPrice(promoCodeAmount)}
                                    {canEdit && <button className="button primary delete-service-button" onClick={e => this.removePromoCode()}>
                                        <span className="fa fa-times"></span>
                                    </button>}

                            </div>}</>
                        }

                    </div>
                        <div className="booking-summary-item">
                            <div className="booking-summary-left">
                            {promoCodeID && canApplyPromo ? <div className="promo-success"><span className="fa fa-check"></span><span> {promoCode} applied successfully</span> </div> :
                                !canApplyPromo && isCodeValidated ? <div className="promo-error"><span className="fa fa-close"></span><span> Unfortunately none of the services selected qualify for this promo code</span></div> :
                                    (!isPromoFound && isCodeValidated) ? <div className="promo-error"><span className="fa fa-close"></span><span> Sorry, that promo code was not found or has expired</span></div> : ''}
                            </div>
                        </div>
                </>}
                {/* Total */}
                <div className="booking-summary-item">
                    <span className={`booking-summary-icon fas fa-money-bill`}></span>
                    <div className="booking-summary-left">
                        Total
                    </div>
                    <div className="booking-summary-right">
                        {
                            anyPOC ? 'Price on consultation' :
                                booking.isSpecific ? formatPrice(booking.specificPrice) :
                                    formatPrice(booking.minPrice, booking.maxPrice)
                        }
                    </div>
                </div>

            </div>

            {/* Total */}
            {(booking.services.length > 0 || booking.packages.length > 0) &&
                <div className="booking-summary-group totals-group">

                    {/* Specific price with deposit */}

                    {booking.isSpecific && salonInfo.enableDeposits && booking.depositAmount > 0 && <>
                        <div className="booking-summary-item" style={{ fontWeight: 'bold' }}>
                            <span className="booking-summary-icon far fa-credit-card"></span>
                            <div className="booking-summary-left">
                                {booking.depositAmount >= booking.specificPrice ?
                                    'Total amount due now' :
                                    'Deposit payable now'
                                }
                            </div>
                            <div className="booking-summary-right">
                                {formatPrice(booking.depositAmount)}
                            </div>
                        </div>
                        {booking.specificPrice - booking.depositAmount > 0 && !anyPOC &&
                            <div className="booking-summary-item">
                                <span className="booking-summary-icon "></span>
                                <div className="booking-summary-left">
                                    Balance in salon
                                </div>
                                <div className="booking-summary-right">
                                    {formatPrice(booking.specificPrice - booking.depositAmount)}
                                </div>
                            </div>
                        }
                    </>}

                    {/* Patch Test */}
                    {anyRequirePatchTest && !booking.isPatchTestConfirmed && !latestPatchTestDate && !nextPatchTestDate &&
                        <div className="booking-summary-item patch-test-warning text-warning">
                            <span className="booking-summary-icon fa fa-exclamation-triangle"></span>
                            <div className="booking-summary-left">

                                {patchTestMessage.split(/\r\n|\r|\n/).map((l, index) =>
                                    <p key={index}>{l}</p>
                                )}

                                <button className="button secondary button-small" onClick={e => this.confirmPatchTest()}>
                                    I've had a patch test
                                </button>

                                <button className="button secondary button-small" onClick={e => this.bookPatchTest()}>
                                    I need a patch test - book now
                                </button>

                            </div>
                        </div>
                    }

                    {anyRequirePatchTest && booking.isPatchTestConfirmed &&
                        <div className="booking-summary-item patch-test-warning text-warning">
                            <span className="booking-summary-icon fa fa-check"></span>
                            <div className="booking-summary-left">

                                You've confirmed that you've had a patch test or colour appointment with us within the last 6 months.

                            </div>
                        </div>
                    }

                    {anyRequirePatchTest && !booking.isPatchTestConfirmed && (latestPatchTestDate || nextPatchTestDate) &&
                        <div className="booking-summary-item patch-test-warning text-warning">
                            <span className="booking-summary-icon fa fa-check"></span>
                            <div className="booking-summary-left">

                                {!!latestPatchTestDate && <>
                                    You had a patch test with us on {moment(latestPatchTestDate).format('DD/MM/YYYY')}.
                                </>}
                                {!latestPatchTestDate && !!nextPatchTestDate && <>
                                    Your patch test is booked in for {moment(nextPatchTestDate).format('DD/MM/YYYY')}.
                                    Your appointment must be at least 48 hours after this.
                                </>}

                            </div>
                        </div>
                    }

                    {booking.isPatchTest &&
                        <div className="booking-summary-item patch-test-warning text-warning">
                            <span className="booking-summary-icon fa fa-check"></span>
                            <div className="booking-summary-left">

                                Once you've finished booking your patch test, you will be taken back to your original booking.

                            </div>
                        </div>
                    }
                </div>
            }

            {/* Log in */}
            {loginDetails && loginDetails.customer &&
                <div className="booking-summary-group totals-group">
                    <div className="booking-summary-item">
                        <span className="booking-summary-icon fas fa-user"></span>
                        <div className="booking-summary-left">
                            Logged in as {loginDetails.customer.firstName} {loginDetails.customer.lastName}
                        </div>
                        <div className="booking-summary-right">

                            <button className="button secondary log-out-button" onClick={e => this.logOut()}>
                                Log out
                            </button>

                        </div>
                    </div>
                </div>
            }

        </>);
    }

}

export default withRouter(BookingPage);